import React, { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './S03_About.module.scss';

const cx = classNames.bind(styles);

const About = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div id="about" className={cx('container')}>
      <div className={cx('top')}>
        <div className={cx('limiter')}>
          <h1>
            플라이그라운드는 창조적인 아름다움을 추구하는 디지털 크리에이티브
            팀입니다.
          </h1>
        </div>
      </div>
      <div className={cx('tabbar')}>
        <ul className={cx('tabs')}>
          <li
            className={cx({ active: activeTabIndex === 0 })}
            onClick={() => setActiveTabIndex(0)}>
            Define
          </li>
          <li
            className={cx({ active: activeTabIndex === 1 })}
            onClick={() => setActiveTabIndex(1)}>
            Design
          </li>
          <li
            className={cx({ active: activeTabIndex === 2 })}
            onClick={() => setActiveTabIndex(2)}>
            Develop
          </li>
        </ul>
      </div>
      <div className={cx('bottom')}>
        <div className={cx('limiter')}>
          {activeTabIndex === 0 && (
            <div className={cx('wrapper')}>
              <h2>Product Definition & Strategy</h2>
              <p>
                우리는 브랜드가 매체 전반에 걸쳐 창의성과 일관성이 필요하다는
                것을 잘 알고, 비즈니스에 대한 깊이 있는 이해를 바탕으로 본질을
                포착하는 독창적인 아이덴티티를 만들어 브랜드 가치를 제고합니다.
              </p>
            </div>
          )}
          {activeTabIndex === 1 && (
            <div className={cx('wrapper')}>
              <h2>UX & UI Design</h2>
              <p>
                우리는 다양한 프로젝트 경험과 최고의 디자인 역량을 바탕으로,
                또한 최신 기술을 고집하여, 사람들을 끌어들일 수 있는 디자인을
                만들고, 사람들의 기억 속에 오래 남을 수 있는 경험을 만드는 데
                전념합니다.
              </p>
            </div>
          )}
          {activeTabIndex === 2 && (
            <div className={cx('wrapper')}>
              <h2>Mobile App Development</h2>
              <p>
                우리는 iOS 및 Android 플랫폼을 전문으로 합니다. 고객이 상상하는
                모바일 서비스를 실제로 만들기 위해 모바일 어플리케이션 개발
                프로세스를 이끌 수 있는 전문지식과 경험을 보유하고 있습니다.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
