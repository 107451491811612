import { DynamoDBClient } from '@aws-sdk/client-dynamodb';

import { REGION, ACCESS_KEY_ID, SECRET_ACCESS_KEY } from '../config/constants';

const ddbClient = new DynamoDBClient({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

export default ddbClient;
