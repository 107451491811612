import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ScanCommand } from '@aws-sdk/client-dynamodb';
import { unmarshall } from '@aws-sdk/util-dynamodb';

import Backward from './S01_Backward';
import Summary from './S02_Summary';
import Info from './S03_Info';
import Slider from './S04_Slider';
import Navigator from './S05_Navigator';
import ddbClient from '../../helpers/ddbClient';

const WorkDetail = () => {
  const params = useParams();
  const [work, setWork] = useState();
  const [prevWork, setPrevWork] = useState();
  const [nextWork, setNextWork] = useState();

  useEffect(() => {
    ReactGA.event({
      category: 'Works',
      action: 'Show Work Detail',
    });

    ddbClient
      .send(new ScanCommand({ TableName: 'FlygroundWorks' }))
      .then((result) => result.Items.map((item) => unmarshall(item)))
      .then((data) => {
        const activeWorks = data.filter((item) => item.active);

        // 조회 프로젝트
        const nextWork = activeWorks.find(
          (work) => work.title.replaceAll(/ /g, '-') === params.slug,
        );
        setWork(nextWork);

        const nextWorkIndex = activeWorks.indexOf(nextWork);

        // 이전 프로젝트
        setPrevWork(
          activeWorks[
            nextWorkIndex === 0 ? activeWorks.length - 1 : nextWorkIndex - 1
          ],
        );
        // 다음 프로젝트
        setNextWork(
          activeWorks[
            nextWorkIndex === activeWorks.length - 1 ? 0 : nextWorkIndex + 1
          ],
        );
      });
  }, [params.slug]);

  return (
    <div>
      <Backward />
      {work && <Summary work={work} />}
      {work && <Info work={work} />}
      {work && <Slider work={work} />}
      {prevWork && nextWork && (
        <Navigator prevWork={prevWork} nextWork={nextWork} />
      )}
    </div>
  );
};

export default WorkDetail;
