import React from 'react';
import classNames from 'classnames/bind';

import styles from './S05_Navigator.module.scss';
import arrowLeft from '../../assets/images/work-detail/arrow-left-black.png';
import arrowRight from '../../assets/images/work-detail/arrow-right-black.png';

const cx = classNames.bind(styles);

const Navigator = ({ prevWork, nextWork }) => {
  const moveToWorkDetail = (title) => {
    const slug = title.replaceAll(/ /g, '-');
    window.location = `/works/${slug}`;
  };

  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <div
          className={cx('column')}
          onClick={() => moveToWorkDetail(prevWork.title)}>
          <div className={cx(['info', 'left'])}>
            <div className={cx('label')}>Previous</div>
            <div className={cx('title')}>{prevWork.title}</div>
            <div className={cx('works')}>{prevWork.works.join(', ')}</div>
            <img src={arrowLeft} alt={prevWork.title} className={cx('arrow')} />
          </div>
          <img
            src={prevWork.thumbImageUrl}
            alt={prevWork.title}
            className={cx('image')}
          />
        </div>
        <div
          className={cx('column')}
          onClick={() => moveToWorkDetail(nextWork.title)}>
          <img
            src={nextWork.thumbImageUrl}
            alt={nextWork.title}
            className={cx('image')}
          />
          <div className={cx(['info', 'right'])}>
            <div className={cx('label')}>Next</div>
            <div className={cx('title')}>{nextWork.title}</div>
            <div className={cx('works')}>{nextWork.works.join(', ')}</div>
            <img
              src={arrowRight}
              alt={nextWork.title}
              className={cx('arrow')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigator;
