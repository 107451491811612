import React from 'react';
import classNames from 'classnames/bind';

import styles from './S02_PageTitle.module.scss';

const cx = classNames.bind(styles);

const PageTitle = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <h1>What we’ve done</h1>
      </div>
    </div>
  );
};

export default PageTitle;
