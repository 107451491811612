import React from 'react';

import Intro from './S01_Intro';
import Marquee from './S02_Marquee';
import About from './S03_About';
import Works from './S04_Works';
import Contact from './S05_Contact';

const Home = () => {
  return (
    <div>
      <Intro />
      <Marquee />
      <About />
      <Works />
      <Contact />
    </div>
  );
};

export default Home;
