import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './S01_Backward.module.scss';
import arrowBack from '../../assets/images/work-detail/arrow-back.png';

const cx = classNames.bind(styles);

const Backward = () => {
  const navigate = useNavigate();

  const moveToWorks = () => {
    navigate('/works');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <img
          src={arrowBack}
          alt="What we’ve done"
          className={cx('arrowBack')}
          onClick={moveToWorks}
        />
        <h1 onClick={moveToWorks}>What we’ve done</h1>
      </div>
    </div>
  );
};

export default Backward;
