import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Header.module.scss';
import logoFull from '../assets/images/header-logo-full.png';
import logoBall from '../assets/images/header-logo-ball.png';

const cx = classNames.bind(styles);

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogoFull, setLogoFull] = useState(true);

  useEffect(() => {
    if (location.hash) {
      let element = window.document.getElementById(location.hash.slice(1));
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 100);
      }
    }

    setLogoFull(location.pathname === '/');
  }, [location]);

  const moveToHome = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  const moveToWork = () => {
    if (location.pathname === '/works') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      navigate('/works');
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('navbar')}>
        <div className={cx('limiter')}>
          <img
            src={isLogoFull ? logoFull : logoBall}
            className={cx(['logo', isLogoFull ? 'full' : 'ball'])}
            onClick={moveToHome}
            alt="flyground.co.kr"
          />
          <div className={cx('menus')}>
            <Link to={{ pathname: '/', hash: 'about' }} className={cx('menu')}>
              About
            </Link>
            <div className={cx('menu')} onClick={moveToWork}>
              Works
            </div>
            <Link
              to={{ pathname: '/', hash: 'contact' }}
              className={cx('menu')}>
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
