import React from 'react';
import classNames from 'classnames/bind';

import styles from './S02_Summary.module.scss';

const cx = classNames.bind(styles);

const Summary = ({ work }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('bottom')}>
        <div className={cx('limiter')}>
          <div className={cx('wrapper')}>
            <h2>{work.title}</h2>
            <div>
              <h3>{work.year}</h3>
              <p>{work.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
