import React, { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './S04_Slider.module.scss';
import { digit } from '../../utils/Formatter';
import arrowLeft from '../../assets/images/work-detail/arrow-left-white.png';
import arrowRight from '../../assets/images/work-detail/arrow-right-white.png';

const cx = classNames.bind(styles);

const Slider = ({ work }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(work.slideImageUrls.length);
    }
  };

  const nextPage = () => {
    if (currentPage < work.slideImageUrls.length) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(1);
    }
  };

  const LeftButton = (
    <div className={cx('button')} onClick={prevPage}>
      <img
        src={arrowLeft}
        alt={currentPage === 1 ? '' : digit(currentPage - 1, 2)}
        className={cx('arrow')}
      />
    </div>
  );

  const RightButton = (
    <div className={cx('button')} onClick={nextPage}>
      <img
        src={arrowRight}
        alt={
          currentPage === work.slideImageUrls.length
            ? ''
            : digit(currentPage + 1, 2)
        }
        className={cx('arrow')}
      />
    </div>
  );

  const Pagination = (
    <div className={cx('pagination')}>
      {digit(currentPage, 2)} / {digit(work.slideImageUrls.length, 2)}
    </div>
  );

  return (
    <div className={cx('container')} style={{ backgroundColor: work.color }}>
      <div className={cx('limiter')}>
        {Pagination}
        <div className={cx('slider')}>
          {LeftButton}
          <div className={cx('imageWrapper')}>
            {work.slideImageUrls.map((slideImageUrl, index) => (
              <img
                key={slideImageUrl}
                src={slideImageUrl}
                alt={digit(currentPage, 2)}
                className={cx(['image', { active: currentPage === index + 1 }])}
              />
            ))}
          </div>
          {RightButton}
        </div>

        {/* 모바일 전용 네비게이터 */}
        <div className={cx('bottomNav')}>
          {LeftButton}
          {Pagination}
          {RightButton}
        </div>
      </div>
    </div>
  );
};

export default Slider;
