import React from 'react';
import classNames from 'classnames/bind';

import styles from './S02_Marquee.module.scss';

const cx = classNames.bind(styles);

const Marquee = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <ul className={cx('track')}>
          <li>Discover & Define</li>
          <li>UX/UI Design Sprints</li>
          <li>Development & Rapid Prototyping</li>

          <li>Discover & Define</li>
          <li>UX/UI Design Sprints</li>
          <li>Development & Rapid Prototyping</li>

          <li>Discover & Define</li>
          <li>UX/UI Design Sprints</li>
          <li>Development & Rapid Prototyping</li>

          <li>Discover & Define</li>
          <li>UX/UI Design Sprints</li>
          <li>Development & Rapid Prototyping</li>

          <li>Discover & Define</li>
          <li>UX/UI Design Sprints</li>
          <li>Development & Rapid Prototyping</li>
        </ul>
      </div>
    </div>
  );
};

export default Marquee;
