import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './S01_Intro.module.scss';
import ball from '../../assets/images/home/ball.png';

const cx = classNames.bind(styles);

const Intro = () => {
  const navigate = useNavigate();

  const moveToWorks = () => {
    navigate('/works');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <h1>
          We Plan, Design
          <br />& Build{' '}
          <img
            src={ball}
            alt="We Plan, Design & Build"
            className={cx('ball')}
          />
          &nbsp; <span className={cx('block')}>We Create</span>
          <br />
          the{' '}
          <span className={cx(['accent', 'block'])} onClick={moveToWorks}>
            Digital Space
          </span>
        </h1>
      </div>
    </div>
  );
};

export default Intro;
