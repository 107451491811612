import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import { ScanCommand } from '@aws-sdk/client-dynamodb';
import { unmarshall } from '@aws-sdk/util-dynamodb';

import styles from './S04_Works.module.scss';
import arrow from '../../assets/images/home/arrow.png';
import ddbClient from '../../helpers/ddbClient';

const cx = classNames.bind(styles);

const Works = () => {
  const navigate = useNavigate();
  const [works, setWorks] = useState();

  useMountEffect(() => {
    ddbClient
      .send(new ScanCommand({ TableName: 'FlygroundWorks' }))
      .then((result) => result.Items.map((item) => unmarshall(item)))
      .then((data) => {
        const nextWorks = data.filter((item) => item.active && item.marked);
        nextWorks.sort((a, b) => (a.order < b.order ? 1 : -1));
        setWorks(nextWorks);
      });
  });

  const moveToWorks = () => {
    navigate(`/works`);
  };

  const moveToWorkDetail = (title) => {
    const slug = title.replaceAll(/ /g, '-');
    navigate(`/works/${slug}`);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('top')}>
        <div className={cx('limiter')}>
          <h1>What we’ve done</h1>
          <div className={cx('button')} onClick={moveToWorks}>
            View more
            <img src={arrow} alt="Move to Works" className={cx('arrow')} />
          </div>
        </div>
      </div>
      <ul className={cx('list')}>
        {works?.map((work) => (
          <li key={work.id} onClick={() => moveToWorkDetail(work.title)}>
            <div className={cx('item')}>
              <div className={cx('title')}>{work.title}</div>
              <div className={cx('category')}>{work.fields.join('/')}</div>
              <div className={cx('year')}>{work.year}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Works;
