import React from 'react';
import classNames from 'classnames/bind';

import styles from './S03_Info.module.scss';

const cx = classNames.bind(styles);

const Info = ({ work }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('mockup')}>
        <img
          src={work.detailImageUrl}
          alt={work.title}
          className={cx('image')}
        />
      </div>
      <div className={cx('bar')}>
        <ul className={cx('items')}>
          <li>
            <h3>Client</h3>
            <p>{work.client}</p>
          </li>
          <li>
            <h3>Type of work</h3>
            <p>{work.works.join(', ')}</p>
          </li>
          <li>
            <h3>Service</h3>
            <p>{work.fields.join(', ')}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Info;
