import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import { ScanCommand } from '@aws-sdk/client-dynamodb';
import { unmarshall } from '@aws-sdk/util-dynamodb';

import styles from './S03_Gallery.module.scss';
import ddbClient from '../../helpers/ddbClient';

const cx = classNames.bind(styles);

const Gallery = () => {
  const navigate = useNavigate();
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [works, setWorks] = useState();

  useMountEffect(() => {
    ddbClient
      .send(new ScanCommand({ TableName: 'FlygroundWorks' }))
      .then((result) => result.Items.map((item) => unmarshall(item)))
      .then((data) => {
        const nextWorks = data.filter((item) => item.active);
        nextWorks.sort((a, b) => (a.order < b.order ? 1 : -1));
        setWorks(nextWorks);
      });
  });

  const moveToWorkDetail = (title) => {
    const slug = title.replaceAll(/ /g, '-');
    navigate(`/works/${slug}`);
  };

  const categories = [
    {
      title: 'All',
      count: works?.length,
    },
    {
      title: 'Website',
      field: 'web',
      count: works?.filter((work) => work.fields.includes('web')).length,
    },
    {
      title: 'Application',
      field: 'app',
      count: works?.filter((work) => work.fields.includes('app')).length,
    },
    {
      title: 'Others',
      field: 'print',
      count: works?.filter((work) => work.fields.includes('print')).length,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <ul className={cx(['cell', 'category'])}>
          {categories.map((category, index) => (
            <li
              key={index}
              className={cx({ active: index === categoryIndex })}
              onClick={() => setCategoryIndex(index)}>
              <div>
                {category.title}
                <sup>({category.count ? category.count : 0})</sup>
              </div>
            </li>
          ))}
        </ul>
        {works?.map((work) => {
          let show = true;
          // 카테고리 선택에 따라 노출여부 정의
          if ([1, 2, 3].includes(categoryIndex)) {
            show = work.fields.includes(categories[categoryIndex].field);
          }

          return (
            <div
              key={work.id}
              className={cx(['cell', 'work', { show }])}
              onClick={() => moveToWorkDetail(work.title)}>
              <div
                className={cx('thumb')}
                style={{ backgroundImage: `url(${work.thumbImageUrl})` }}>
                <div className={cx('label')}>{work.fields.join('/')}</div>
              </div>
              <div className={cx('info')}>
                <div className={cx('client')}>{work.client}</div>
                <div className={cx('title')}>{work.title}</div>
                <div className={cx('works')}>{work.works.join(', ')}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
