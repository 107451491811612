import React from 'react';
import classNames from 'classnames/bind';

import styles from './Footer.module.scss';
import ball from '../assets/images/footer-ball.png';

const cx = classNames.bind(styles);

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('infoWrapper')}>
        <ul className={cx('info')}>
          <li>
            <h3>Company</h3>
            <p>FLYGROUND Corporation</p>
          </li>
          <li>
            <h3>Registration No.</h3>
            <p>676-88-01522</p>
          </li>
          <li>
            <h3>Headquarter</h3>
            <p>
              39-1, Seongsuil-ro 8-gil
              <br />
              Seongdong-gu, Seoul, <br className={cx('br')} />
              Republic of Korea
            </p>
          </li>
          <li>
            <h3>Designer Office</h3>
            <p>
              27, Wonhyo-ro 63-gil
              <br />
              Yongsan-gu, Seoul, <br className={cx('br')} />
              Republic of Korea
            </p>
          </li>
          <li>
            <h3>CEO</h3>
            <p>Park Jungyeon</p>
            <h3>COO</h3>
            <p>Lee Donghwa</p>
          </li>
        </ul>
      </div>
      <div className={cx('geoWrapper')}>
        <div className={cx('geo')}>37°32’45”&nbsp;&nbsp;127°03’18”</div>
        <img
          src={ball}
          alt="FLYGROUND"
          className={cx('ball')}
          onClick={scrollToTop}
        />
        <div className={cx('copyright')}>© FLYGROUND</div>
      </div>
    </div>
  );
};

export default Footer;
