import React from 'react';

import Marquee from './S01_Marquee';
import PageTitle from './S02_PageTitle';
import Gallery from './S03_Gallery';

const Works = () => {
  return (
    <div>
      <Marquee />
      <PageTitle />
      <Gallery />
    </div>
  );
};

export default Works;
