import React, { useState } from 'react';
import classNames from 'classnames/bind';
import axios from 'axios';

import styles from './S05_Contact.module.scss';

const cx = classNames.bind(styles);

const Contact = () => {
  const [info, setInfo] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const send = () => {
    const request = {
      key: '115cf9c1-a385-483d-83e3-5cdddca7e88c',
      to: 'donghwa.lee@flyground.co.kr',
      subject: 'FLYGROUND 웹사이트 문의 접수',
      html: `
        <ul>
          <li>성명<br />${info.name}</li>
          <li>이메일<br />${info.email}</li>
          <li>내용<br />${info.message}</li>
        </ul>
      `,
    };

    axios
      .post('https://flymail.apis.flyground.co.kr/send', request)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          alert('정상적으로 접수되었습니다.');
          window.location.href = '/';
        } else {
          alert('예기치 못한 오류가 발생했습니다.');
        }
      })
      .catch((error) => {
        alert('예기치 못한 오류가 발생했습니다.');
      });
  };

  return (
    <div id="contact" className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('limiter')}>
          <div className={cx('left')}>
            <h1>
              Got a project <span className={cx('block')}>to discuss?</span>
              <br />
              Contact us.
            </h1>
            <h2>
              <a
                href="mailto:official@flyground.co.kr"
                target="_blank"
                rel="noreferrer">
                official@flyground.co.kr
              </a>
            </h2>
          </div>
          <div className={cx('right')}>
            <input
              name="name"
              type="text"
              defaultValue={info.name}
              placeholder="Name"
              onChange={handleInputChange}
            />
            <input
              name="email"
              type="text"
              defaultValue={info.email}
              placeholder="Email"
              onChange={handleInputChange}
            />
            <input
              name="message"
              type="text"
              defaultValue={info.message}
              placeholder="Message"
              onChange={handleInputChange}
            />
            <div className={cx('button')} onClick={send}>
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
